import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { catchError, BehaviorSubject } from 'rxjs';
import { RBARole } from '@services/rba/rba-settings.interface';
import { IUserGetAdmins } from '@model/interfaces/user.interface';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { PatternService } from '@shared/services/pattern.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'mt-invite-external-admin',
  templateUrl: './invite-external-admin.component.html',
  styleUrls: ['./invite-external-admin.component.scss']
})
export class InviteExternalAdminComponent {
  protected alertService = inject(AlertService);
  protected fb = inject(NonNullableFormBuilder);
  protected modal = inject(NgbActiveModal);
  protected pattern = inject(PatternService);
  protected translate = inject(TranslocoService);
  protected userService = inject(UserService);

  /**
   * Advanced admin list
   * @defaultValue []
   */
  @Input() administrators: IUserGetAdmins[] = [];

  /**
   * Roles list
   * @defaultValue []
   */
  @Input() roles: RBARole[] = [];

  /**
   * Observable is used to hold the value of info box details
   */
  private _showModalInfoBox$ = new BehaviorSubject({ isShow: false, infoMessage: '' });

  /**
   * Observable is used to holds the value of selected roles
   */
  private _selectedRoles$ = new BehaviorSubject<RBARole[]>([]);

  /**
   * Handles the stream of info box details
   */
  showModalInfoBox$ = this._showModalInfoBox$.asObservable();

  /**
   * Handles the stream of selected roles and manages the selected roles
   */
  selectedRoles$ = this._selectedRoles$.asObservable();

  /**
   * Trigger the data to show infobox in the modal
   */
  set modalInfoDetail(details: { isShow: boolean; infoMessage: string }) {
    this._showModalInfoBox$.next({ isShow: details.isShow, infoMessage: details.infoMessage });
  }

  /**
   * Trigger the data on select/deselect the role
   */
  set selectRoles(rolesList: RBARole[]) {
    this._selectedRoles$.next(rolesList);
  }

  /**
   * Invitation form
   */
  invitationForm = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(this.pattern.EMailPattern)]]
  });

  /**
   * @remarks Can not move `sendAdvancedAdminInvitation` and `sendExternalAdminInvitation` method to the modal opener component
   * because we need to display some error message based on api response in the modal
   *
   * Method is used to send invitation to the advanced admin or external admin
   * @param email - Email address of admin
   * @param rolesList - Assigned roles
   */
  sendInvitation(email: string, rolesList: RBARole[]): void {
    // Check new admin is already in the list or not
    const checkAdmin = this.administrators.some(admin => admin.email === email);
    if (checkAdmin) {
      this.modalInfoDetail = { isShow: true, infoMessage: 'admin_already_existing' };
      return;
    }

    const roles = rolesList.map(role => ({
      ...role,
      titleTag: this.translate.translate(role.titleTag),
      descriptionTag: this.translate.translate(role.descriptionTag)
    }));

    const sendInvitation$ =
      this.roles.length > 0
        ? this.userService.sendAdvancedAdminInvitation(email, roles, this.translate.getActiveLang())
        : this.userService.sendExternalAdminInvitation(email);

    sendInvitation$
      .pipe(
        catchError((error: string) => {
          void this.alertService.defaultErrorMessage(error);
          throw error;
        })
      )
      .subscribe(result => {
        if (result.success) {
          this.modal.close(true);
          void this.alertService.defaultSuccessMessage(this.alertService.translateChangesSaved());
        } else {
          switch (result.message) {
            case 'EXTERNAL_ADMIN_ALREADY_EXISTING':
              this.modalInfoDetail = { isShow: true, infoMessage: 'external_admin_already_exists' };
              break;
            case 'NON_EXISTING_ADMIN':
              this.modalInfoDetail = { isShow: true, infoMessage: 'external_admin_not_found' };
              break;
            case 'ADMIN_OF_THIS_ACCOUNT':
              this.modalInfoDetail = { isShow: true, infoMessage: 'admin_of_this_account' };
              break;
            default:
              void this.alertService.defaultErrorMessage(this.alertService.translateTechnicalError());
              break;
          }
        }
      });
  }
}
