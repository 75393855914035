import { Inject, Injectable, InjectionToken, OnDestroy, Optional } from '@angular/core';

// Operators
import { cloneDeep, isEqual } from 'lodash-es';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription,
  switchMap
} from 'rxjs';

// Services
import { DatasetService } from 'src/app/services/dataset/dataset.service';
import { SignatureDataService } from 'src/app/services/signature-data/signature-data.service';
import { StoreService } from 'src/app/services/store/store.service';
import { TemplateService } from 'src/app/services/template/template.service';
import { UserSessionService } from 'src/app/services/user-session/user-session.service';

// Interfaces
import { GeneratorState } from 'src/app/model/interfaces/generator-state.interface';
import {
  IntegrationsSignature,
  ISignature,
  ISignatureData,
  SignatureTemplateFieldsType,
  SignatureUnsavedData,
  TSignaturePlaceholder
} from 'src/app/model/interfaces/signature.interface';
import { TemplateEntry } from 'src/app/model/template.entry';
import {
  COMPANY_FIELDS_MAPPING,
  EMPLOYEE_FIELDS_MAPPING,
  SIGNATURE_FIELDS_MAPPING,
  SIGNATURE_INPUTS
} from './generator-store-service.interface';
import { IDataset } from 'src/app/model/interfaces/dataset.interface';

// Enums
import { ELanguage } from '@model/enums/language.enum';

const initialState: GeneratorState = {
  templates: [],
  selectedTemplateKey: '01',
  dummy: null,
  info: null,
  design: {
    design_primary_color: '#6a67ff',
    design_font_family: 'Arial',
    design_font_size: 'medium',
    design_font_color: '#2f2f2f',
    design_disclaimer_font_color: '#c6c6c6',
    design_social_media_icons_color: '#6a67ff',
    design_social_media_icons_shape: 'radius-square',
    design_social_media_icons_size: 'medium',
    design_social_media_icons_path: 'https://cdn.mailtastic.com/img/siggen/',
    design_profile_picture_shape: 'square',
    design_profile_picture_size: 'medium',
    design_logo_size: 'medium',
    design_use_mailtastic: false
  },
  banner: null,
  languageOfDesignation: 'en',
  imageUrl: '',
  addIfToHTML: false
};

const MIN_DESIGN_FIELDS_NUM = 12;

export const DEFAULT_UNSAVEDDATA: SignatureUnsavedData = {
  isCompanyFormUnsaved: false,
  isEmployeeFormUnsaved: false,
  isSignatureFormUnsaved: false,
  isSignatureGraphicUnsaved: false,
  isSignatureTitleUnsaved: false
};
export const OwnGeneratorStoreService = new InjectionToken<GeneratorStoreService>('OwnGeneratorStoreService');
export const OwnGeneratorStoreServiceId = new InjectionToken<string>('OwnGeneratorStoreServiceId');

@Injectable({
  providedIn: 'root'
})
export class GeneratorStoreService extends StoreService<GeneratorState> implements OnDestroy {
  /**
   * Signature information inputs object used to show/hide or lock/unlock the fields
   * @remarks `cloneDeep` is used because if we do logout & register a new account then reference is not destroyed.
   */
  SIGNATURE_INPUTS = cloneDeep(SIGNATURE_INPUTS);

  copySignatureContent = new Subject<{ isCopy: boolean; isHtmlCopy: boolean }>();
  copySignatureContent$ = this.copySignatureContent.asObservable();

  signatureHTML = new Subject<string>();
  signatureHTML$ = this.signatureHTML.asObservable();

  private isUnsavedSignatureDetails = new BehaviorSubject<SignatureUnsavedData>(DEFAULT_UNSAVEDDATA);
  isUnsavedSignatureDetails$ = this.isUnsavedSignatureDetails.asObservable();

  private isBackClick = new BehaviorSubject<boolean>(false);
  isBackClick$ = this.isBackClick.asObservable();

  /**
   * Variable is used to store hided fields of signature preview
   * Fields can be add or remove from it
   * @defaultValue []
   */
  private disableSignatureFields: string[] = [];

  /**
   * Observable is used to trigger hided fields of signature preview
   */
  private _setDisableSignatureField$ = new ReplaySubject<string[]>();
  setDisableSignatureField$ = this._setDisableSignatureField$.asObservable();

  templates$: Observable<TemplateEntry[]> = this.select(state => state.templates).pipe(
    distinctUntilChanged((prev, curr) => isEqual(prev, curr))
  );
  info$: Observable<any> = this.select(state => state.info).pipe(
    distinctUntilChanged((prev, curr) => isEqual(prev, curr))
  );
  design$: Observable<any> = this.select(state => state.design).pipe(
    distinctUntilChanged((prev, curr) => isEqual(prev, curr))
  );
  dummy$: Observable<any> = this.select(state => state.dummy).pipe(
    distinctUntilChanged((prev, curr) => isEqual(prev, curr))
  );
  banner$: Observable<any> = this.select(state => state.banner).pipe(
    distinctUntilChanged((prev, curr) => isEqual(prev, curr))
  );
  addIfToHTML: Observable<boolean> = this.select(state => state.addIfToHTML).pipe(
    distinctUntilChanged((prev, curr) => isEqual(prev, curr))
  );

  /**
   * Observable is used for language of the signature template
   */
  languageOfDesignation$: Observable<ELanguage> = this.select(state => state.languageOfDesignation).pipe(
    distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
    map(language => language as ELanguage)
  );

  imageUrl$: Observable<string> = this.select(state => state.imageUrl).pipe(
    distinctUntilChanged((prev, curr) => isEqual(prev, curr))
  );
  selectedTemplate$: Observable<TemplateEntry | undefined> = this.select(state => {
    return state.templates.find(item => item.key === state.selectedTemplateKey);
  }).pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)));

  private loadSubscription!: Subscription;
  public loadObserver = new BehaviorSubject<boolean>(false);

  private loadCompanyData$ = this.datasetService.getDefaultDataSets().pipe(
    map(datasets => datasets.companyDatasetId),
    switchMap(companyDatasetId => this.datasetService.getOne(companyDatasetId)),
    map(companyDataset => this.signatureDataService.getCompanySignatureData(companyDataset))
  );

  constructor(
    private datasetService: DatasetService,
    private signatureDataService: SignatureDataService,
    private templateService: TemplateService,
    private userSessionService: UserSessionService,
    @Optional() @Inject(OwnGeneratorStoreServiceId) public id: string
  ) {
    super(initialState);
  }

  ngOnDestroy(): void {
    this.loadSubscription?.unsubscribe();
    this.userSessionService.destroy();
  }

  /**
   *  Loading initial information (dummy or saved data)
   * @remarks
   * This `companyData` only avaialble when signature loads from endusercockpit installation modal
   *
   * @param companyData - The company data
   * @param isLoadDefault - Boolean to set with company & employee data by default
   */
  load(isLoadDefault = true, companyData?: string): void {
    let loadCompanyDetails$ = this.loadCompanyData$;

    if (companyData) {
      const dataSet = {
        owner: '',
        type: 'company',
        id: '',
        data: JSON.stringify(companyData)
      } as IDataset;

      const dcompanySigData = this.signatureDataService.getCompanySignatureData(dataSet);
      loadCompanyDetails$ = of(dcompanySigData);
    }

    const templates$ = this.templateService.getMany();
    const data$ = this.userSessionService.get().pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)));
    const dummy$ = this.templateService.getDummyInfo();

    this.loadSubscription?.unsubscribe();
    this.loadObserver.next(false);
    const combined$ = combineLatest([templates$, data$, dummy$, loadCompanyDetails$]);

    this.loadSubscription = combined$.subscribe(([templates, data, dummy, companyData]) => {
      // If company data is not set, then we are applying company dataset data for the first time
      if (isLoadDefault && data?.info) {
        data.info = { ...data.info, ...companyData }; // merge company data with user data
      }

      const { design: dummyDesign, campaign: dummyCampaign, ...rest } = dummy;

      const { info, design, campaign, selectedTemplateKey = '09', banner } = data || {};

      this.userSessionService.save({
        info,
        design,
        campaign,
        selectedTemplateKey
      });
      this.setState({
        templates,
        selectedTemplateKey,
        info,
        design: Object.assign({}, dummyDesign, design || {}),
        dummy: rest,
        banner
      });
      this.loadObserver.next(true);
    });
  }

  /**
   * Selecting desired template
   * @param {TemplateEntry} template -selected template
   */
  selectTemplate(template: TemplateEntry): void {
    const params = { selectedTemplateKey: template.key };
    this.userSessionService.save(params);
    this.setState(params);
  }

  /**
   * Changing selected language for signature template
   * @param lang -selected language
   */
  languageOfDesignationChange(lang?: ELanguage): void {
    const params = { languageOfDesignation: lang ?? ELanguage.EN };
    this.userSessionService.save(params);
    this.setState(params);
  }

  /**
   * Changing photo size
   * @param {any} size -photo size
   */
  photoSizeChange(size: any): void {
    if (size === 'small') {
      this.state.imageUrl.replace('100', '80');
    }
  }

  /**
   * Clearing data from user session
   */
  clearData() {
    this.loadSubscription?.unsubscribe();
    this.userSessionService.destroy();
  }

  /**
   * Saving current signature data to user session
   * @param {string} key - which information is saved - e.g. info, design
   * @param {any} data - the data which is saved
   */
  save(key: string, data: any): void {
    const params = { [key]: data };
    this.userSessionService.save(params);
    this.setState(params);
  }

  /**
   * Getting saved information about signature
   * @returns {Observable<any>} - observable which stores all saved values
   */
  get(): Observable<any> {
    return this.userSessionService.get();
  }

  /**
   * Preparing saved data for the backend
   * @returns {ISignatureData} data for company, employee and signature design
   */
  getDataToEdit(): ISignatureData {
    let dataToEditObject: any = {};
    this.userSessionService.get().subscribe(style => {
      let signatureDesign = style?.design;
      // if design object has less than 12 fields, that means that user left default values as design
      if (!style?.design || Object.keys(signatureDesign).length < MIN_DESIGN_FIELDS_NUM) {
        signatureDesign = {
          design_primary_color: '#6a67ff',
          design_font_family: 'Arial',
          design_font_size: 'medium',
          design_font_color: '#2f2f2f',
          design_disclaimer_font_color: '#c6c6c6',
          design_social_media_icons_color: '#6a67ff',
          design_social_media_icons_shape: 'radius-square',
          design_social_media_icons_size: 'medium',
          design_social_media_icons_path: 'https://cdn.mailtastic.com/img/siggen/',
          design_profile_picture_shape: 'square',
          design_profile_picture_size: 'medium',
          design_logo_size: 'medium',
          design_use_mailtastic: false
        };
      }

      const socialMediaIconDimension = {
        imgdimension: {
          mode: 'default',
          shape: signatureDesign ? signatureDesign.design_social_media_icons_shape : '',
          width: '',
          height: ''
        }
      };

      dataToEditObject = {
        company: {
          ...this.SIGNATURE_INPUTS.company,
          u_logo: {
            ...this.SIGNATURE_INPUTS.company.u_logo,
            imgdimension: {
              mode: 'default',
              width: '',
              height: ''
            }
          },
          c_tiktok: {
            ...this.SIGNATURE_INPUTS.company.c_tiktok,
            ...socialMediaIconDimension
          },
          u_linkedin: {
            ...this.SIGNATURE_INPUTS.company.u_linkedin,
            ...socialMediaIconDimension
          },
          u_xing: {
            ...this.SIGNATURE_INPUTS.company.u_xing,
            ...socialMediaIconDimension
          },
          u_fb: {
            ...this.SIGNATURE_INPUTS.company.u_fb,
            ...socialMediaIconDimension
          },
          u_twitter: {
            ...this.SIGNATURE_INPUTS.company.u_twitter,
            ...socialMediaIconDimension
          },
          u_instagram: {
            ...this.SIGNATURE_INPUTS.company.u_instagram,
            ...socialMediaIconDimension
          },
          u_youtube: {
            ...this.SIGNATURE_INPUTS.company.u_youtube,
            ...socialMediaIconDimension
          },
          u_pinterest: {
            ...this.SIGNATURE_INPUTS.company.u_pinterest,
            ...socialMediaIconDimension
          },
          u_whatsapp: {
            ...this.SIGNATURE_INPUTS.company.u_whatsapp,
            ...socialMediaIconDimension
          },
          u_snapc: {
            ...this.SIGNATURE_INPUTS.company.u_snapc,
            ...socialMediaIconDimension
          },
          u_googlep: {
            ...this.SIGNATURE_INPUTS.company.u_googlep,
            ...socialMediaIconDimension
          },
          u_blog: {
            ...this.SIGNATURE_INPUTS.company.u_blog,
            ...socialMediaIconDimension
          }
        },
        employee: {
          ...this.SIGNATURE_INPUTS.employee,
          ma_foto: {
            ...this.SIGNATURE_INPUTS.employee.ma_foto,
            imgdimension: {
              mode: 'default',
              shape: signatureDesign ? signatureDesign.design_profile_picture_shape : '',
              width: '',
              height: ''
            }
          },
          emp_tiktok: {
            ...this.SIGNATURE_INPUTS.employee.emp_tiktok,
            ...socialMediaIconDimension
          },
          ma_skype: {
            ...this.SIGNATURE_INPUTS.employee.ma_skype,
            ...socialMediaIconDimension
          },
          ma_fb: {
            ...this.SIGNATURE_INPUTS.employee.ma_fb,
            ...socialMediaIconDimension
          },
          ma_googlep: {
            ...this.SIGNATURE_INPUTS.employee.ma_googlep,
            ...socialMediaIconDimension
          },
          ma_instagram: {
            ...this.SIGNATURE_INPUTS.employee.ma_instagram,
            ...socialMediaIconDimension
          },
          ma_twitter: {
            ...this.SIGNATURE_INPUTS.employee.ma_twitter,
            ...socialMediaIconDimension
          },
          ma_linkedin: {
            ...this.SIGNATURE_INPUTS.employee.ma_linkedin,
            ...socialMediaIconDimension
          },
          ma_xing: {
            ...this.SIGNATURE_INPUTS.employee.ma_xing,
            ...socialMediaIconDimension
          },
          ma_ms_teams: {
            ...this.SIGNATURE_INPUTS.employee.ma_ms_teams,
            ...socialMediaIconDimension
          }
        },
        design: { ...signatureDesign },
        information: {
          sig_grafik: {
            ...this.SIGNATURE_INPUTS.information.sig_grafik,
            imgdimension: {
              mode: 'default',
              width: '',
              height: ''
            }
          }
        }
      };
    });
    return dataToEditObject;
  }

  /**
   * Setting locked/unlocked arrays based on provided signature. This is used when users wants to edit signature
   * @param signature - signature object
   */
  setLockedInformation(signature: ISignature): void {
    const signatureData = this.signatureDataService.getSignatureData(signature);
    const EMPLOYEE = this.SIGNATURE_INPUTS.employee;
    for (const field in signatureData.employee) {
      EMPLOYEE[field as keyof typeof EMPLOYEE].locked = (signatureData.employee[field] as TSignaturePlaceholder).locked;
    }

    const COMPANY = this.SIGNATURE_INPUTS.company;
    for (const field in signatureData.company) {
      COMPANY[field as keyof typeof COMPANY].locked = (signatureData.company[field] as TSignaturePlaceholder).locked;
    }
  }

  /**
   * Setting show/hide arrays based on provided signature. This is used when users wants to show/hide signature fields
   * @param signature - signature object
   */
  setDisabledInformation(signature: ISignature | IntegrationsSignature): void {
    const signatureData = this.signatureDataService.getSignatureData(signature);
    this.disableSignatureFields = [];
    const EMPLOYEE = this.SIGNATURE_INPUTS.employee;
    for (const field in signatureData.employee) {
      const placeholder = signatureData.employee[field] as TSignaturePlaceholder;

      if (placeholder.disabled === true) {
        this.disableSignatureFields.push(EMPLOYEE_FIELDS_MAPPING[field as keyof typeof EMPLOYEE_FIELDS_MAPPING]);
      }
      if (EMPLOYEE[field as keyof typeof EMPLOYEE]) {
        EMPLOYEE[field as keyof typeof EMPLOYEE].disabled = placeholder.disabled;
      }
    }

    const COMPANY = this.SIGNATURE_INPUTS.company;
    for (const field in signatureData.company) {
      const placeholder = signatureData.company[field] as TSignaturePlaceholder;

      if (placeholder.disabled === true) {
        this.disableSignatureFields.push(COMPANY_FIELDS_MAPPING[field as keyof typeof COMPANY_FIELDS_MAPPING]);
      }
      if (COMPANY[field as keyof typeof COMPANY]) {
        COMPANY[field as keyof typeof COMPANY].disabled = placeholder.disabled;
      }
    }

    const INFORMATION = this.SIGNATURE_INPUTS.information;
    for (const field in signatureData.information) {
      const placeholder = signatureData.information[field] as TSignaturePlaceholder;

      if (placeholder.disabled === true) {
        this.disableSignatureFields.push(field);
      }
      if (INFORMATION[field as keyof typeof INFORMATION]) {
        INFORMATION[field as keyof typeof INFORMATION].disabled = placeholder.disabled;
      }
    }
    this.triggerDisableSignatureFieldClick(this.disableSignatureFields);
  }

  /**
   * Update unsaved detail about signature
   * @param data - The data of changed signature
   */
  setDataUnsaved(data: SignatureUnsavedData): void {
    this.isUnsavedSignatureDetails.next(data);
  }

  /**
   * Updates status of back click for unsaved detail about signature
   * @param data - The status of back click
   */
  setBackClickTrigger(data: boolean): void {
    this.isBackClick.next(data);
  }

  /**
   * Updates field name which will be show or hide from signature preview
   * @param field - Field name to show/hide
   * @param disable - Status of field, true for hide & false for show
   * @param info - Which information is show/hide company info or personal info
   */
  setDisableSignatureFieldClick(fieldName: string, disable: boolean, info: SignatureTemplateFieldsType): void {
    const mappingFields =
      info === 'personal'
        ? EMPLOYEE_FIELDS_MAPPING
        : info === 'company'
        ? COMPANY_FIELDS_MAPPING
        : SIGNATURE_FIELDS_MAPPING;

    const fieldMap = mappingFields[fieldName as keyof typeof mappingFields];

    if (!disable) {
      const index = this.disableSignatureFields.indexOf(fieldMap);
      if (index !== -1) this.disableSignatureFields.splice(index, 1);
    } else this.disableSignatureFields.push(fieldMap);
    this.triggerDisableSignatureFieldClick(this.disableSignatureFields);
  }

  /**
   * Trigges disabled field names to signature preview
   * @param fieldNames - Field names to trigger
   */
  triggerDisableSignatureFieldClick(fieldNames: string[]): void {
    this._setDisableSignatureField$.next(fieldNames);
  }
}
