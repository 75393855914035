@if(selectedRoles$ | async; as selectedRoles) {
<mt-custom-modal
  headline="{{
    translate(!!roles.length ? 'invite_user' : 'createNewExternalAdminstrator')
  }}"
  *transloco="let translate"
>
  <ng-container modal-body>
    <div class="content" [formGroup]="invitationForm">
      @if(!!roles.length) {
      <roles-selection
        [roles]="roles"
        (selectedRoles)="selectRoles = $event"
      ></roles-selection>
      }
      <div class="form-group">
        <div class="schrift_blau infoline">
          {{ translate('send_to_which_mailaddy') }}
        </div>
        <input
          name="inviteAdminEmail"
          type="text"
          class="form-control form input-sm"
          formControlName="email"
        />
        @if(invitationForm.controls.email.touched ||
        invitationForm.controls.email.dirty) {
        <div>
          <!-- Validate required email -->
          @if(invitationForm.controls.email.errors?.required) {
          <div class="martop10 text-danger">
            <span>{{ translate('E-Mail-Adresse') }}</span>
            <span>{{ translate('required_field') | lowercase }}</span>
          </div>
          }

          <!-- Validate correct email format -->
          @if(invitationForm.controls.email.errors?.pattern) {
          <div class="martop10 text-danger">
            <span>{{ translate('Pleasevalidemailaddress') }}</span>
          </div>
          }
        </div>
        }
      </div>
    </div>

    @if(showModalInfoBox$ | async; as showInfoBox) {
    <div
      class="infobox full-width-imp infobox-info error-message-info"
      *ngIf="showInfoBox.isShow"
    >
      <span class="symbol glyphicon glyphicon-question-sign"></span>
      <div class="text">{{ translate(showInfoBox.infoMessage) }}</div>
    </div>
    }
  </ng-container>
  <ng-container modal-footer>
    <div class="flex-center-right">
      <div class="margin-left">
        <mt-button
          (click)="modal.close()"
          class="mt_left"
          color="default"
          text="abort"
        ></mt-button>
        <mt-button
          (click)="
            sendInvitation(invitationForm.controls.email.value, selectedRoles)
          "
          [disabled]="
            (!roles.length && !!invitationForm.controls.email.errors) ||
            (!!roles.length &&
              (!!invitationForm.controls.email.errors || !selectedRoles.length))
          "
          color="primary"
          icon="plus"
          text="send_invitation"
        >
        </mt-button>
      </div>
    </div>
  </ng-container>
</mt-custom-modal>
}
