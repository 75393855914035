import { Inject, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { differenceInDays, format, parseISO } from 'date-fns';
import { Observable } from 'rxjs';
import { EBoxType } from 'src/app/model/enums/box-type.enum';
import { MailtasticAuthorizationDetails } from 'src/app/model/enums/mailtastic-authorization-details.enum';
import { TargetSender } from 'src/app/model/enums/target-sender.enum';
import { IEventCreate, IEventDate } from 'src/app/model/interfaces/event.interface';
import { IWizardTabComponent } from 'src/app/model/interfaces/wizard.interface';
import { DefaultSimpleMessageComponent } from 'src/app/shared/components/organisms/modals/default/default-simple-message/default-simple-message.component';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IEventCheckDate } from '../event/event-service.interface';
import { EventService } from '../event/event.service';
import { ID_OBJECT } from '../list-helper/list-helper-service.interface';
import { AccountService } from '@services/account/account.service';
import { DOCUMENT } from '@angular/common';
import { ImageDimensionWithMode, TImageDimensionDataMode } from '@model/interfaces/signature.interface';

export interface IUtilGetDeltaInstances<T> {
  added: T;
  changed: T;
  deleted: T;
}

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private accountService: AccountService,
    private authService: AuthService,
    private eventService: EventService,
    private modalService: NgbModal,
    private translateService: TranslocoService
  ) {}

  /**
   * Gets the resized dimensions of a scaled image based on altered width.
   * @param originalImage - Original image dimensions
   * @param alteredImage - Altered image dimensions
   * @param noFloor - Math.floor is used when is true
   * @returns Image with new dimensions from altered Image
   */
  getResizedImageDimensions(
    originalImage: HTMLImageElement,
    alteredImage: HTMLImageElement,
    noFloor = false
  ): HTMLImageElement {
    let alteredHeight;

    if (alteredImage.width === 0 || originalImage.width === 0 || originalImage.height === 0) {
      alteredImage.width = 0;
      alteredImage.height = 0;
    } else {
      const factor = originalImage.width / alteredImage.width;
      const calculatedHeight = originalImage.height / factor;

      //calc and set height
      alteredHeight = noFloor ? calculatedHeight : Math.floor(calculatedHeight);
    }

    return new Image(alteredImage.width, alteredHeight);
  }

  /**
   * Gets the string value of the saved value for the image.
   * @param value - Image string URL
   * @returns Image string URL
   */
  getImageFormImageAsString(value: string): string {
    const isString = value;
    if (typeof isString === 'string') return isString;
    else return '';
  }

  /**
   * Creates various information of two arrays
   * @param source - Target group source
   * @param updated - Updated Target Group
   * @returns Added, changed and deleted Target Groups
   */
  getDeltaOfInstances<T extends { id: string | number }>(source: T[], updated: T[]): IUtilGetDeltaInstances<T[]> {
    let added: T[] = [],
      changed: T[] = [],
      deleted: T[] = [];

    if (source && updated && Array.isArray(source) && Array.isArray(updated)) {
      added = updated.filter(updatedItem => !source.find(sourceItem => sourceItem.id === updatedItem.id));
      changed = updated.filter(updatedItem => {
        return source.find(sourceItem => {
          return updatedItem.id === sourceItem.id;
        });
      });
      deleted = source.filter(sourceItem => {
        return !updated.find(updatedItem => {
          return updatedItem.id === sourceItem.id;
        });
      });
    }

    return {
      added,
      changed,
      deleted
    };
  }

  /**
   * Formats date to be displayed in the input field.
   * @remarks Default will format as dd.MM.yyyy
   * @param date - Start date
   * @returns Formated data
   */
  formatSingleDate(date: NgbDate, dateFormat = 'dd.MM.yyyy'): string {
    return format(new Date(date.year, date.month - 1, date.day), dateFormat);
  }

  /**
   * @deprecated - //TODO: REPLACE WITH PIPE
   * Formats the dates into a string representation with start and end values
   * @param firstDate - First date to format
   * @param secondDate - Second date to format
   * @returns String representing start and end values OR false
   */
  formatDate(firstDate: Date | string, secondDate?: Date | string): string {
    const first = parseISO(firstDate.toString());
    const second = secondDate ? parseISO(secondDate.toString()) : false;

    if (first && second) {
      if (format(first, 'MMM') === format(second, 'MMM')) {
        if (format(first, 'yyyy') === format(second, 'yyyy')) {
          return `${format(first, 'dd')}.-${format(second, 'dd. MMM. yyyy')}`;
        } else {
          return `${format(first, 'dd. MMM yyyy')} ${format(second, 'dd. MMM yyyy')}`;
        }
      } else {
        if (format(first, 'yyyy') === format(second, 'yyyy')) {
          return `${format(first, 'dd. MMM -')} ${format(second, 'dd. MMM yyyy')}`;
        } else {
          return `${format(first, 'dd. MMM yyyy')} - ${format(second, 'dd. MMM yyyy')}`;
        }
      }
    } else if (first) {
      return format(first, 'dd. MMM yyyy');
    }
    return '';
  }

  /**
   * Calculates the difference in days between two dates
   * @param firstDate - First date to compare
   * @param secondDate - Second date to compare
   * @returns Difference in days between two dates, including the last day
   */
  differenceDate(firstDate?: Date | string, secondDate?: Date | string): number {
    if (firstDate && secondDate) {
      const first = parseISO(firstDate.toString());
      const second = parseISO(secondDate.toString());

      return differenceInDays(second, first) + 1; // +1 to include the last day
    }
    return 0;
  }

  /**
   * Formating date from NgbDate to Date
   * @param date - Date to format
   * @param addDay - Add day based on requirement ex. when we create durable event and start date can not be today so in that case we should add +1 day
   * @returns ISO format date
   */
  parseNgbDate(date: NgbDate, addDay = 1): Date {
    return new Date(date.year, date.month - 1, date.day + addDay);
  }

  /**
   * Formating date from Date to NgbDate
   * @param date - Date to format
   * @returns ISO format date
   */
  parseDate(date: Date): NgbDateStruct | NgbDate | undefined {
    if (date.toString() !== '0000-00-00') {
      const dateToParse = new Date(date);

      return {
        day: dateToParse.getDate(),
        month: dateToParse.getMonth() + 1,
        year: dateToParse.getUTCFullYear()
      };
    }
    return undefined;
  }

  /**
   * Gets default date
   * @returns ISO format date
   */
  getDefaultDate(): Date {
    return '0000-00-00' as unknown as Date;
  }

  /**
   * Gets default date as string
   * @returns Default date in string
   */
  getDefaultDateAsString(): string {
    return '0000-00-00';
  }

  /**
   * Gets tomorrow date
   * @returns Date of tomorrow as string
   */
  getTomorrowDate(): string {
    const date = new Date();
    const tomorrow = format(date.setDate(date.getDate() + 1), 'yyyy-MM-dd');
    return tomorrow;
  }

  /**
   * Check that date is valid or not
   * @param date - Date to format
   * @returns That date is valid or not
   */
  isValidDate(date?: Date | NgbDate): boolean {
    return date ? date.toString() !== '0000-00-00' : false;
  }

  /**
   * Checks the event date
   */
  checkEventDate(type: TargetSender, startDate: NgbDate, endDate?: NgbDate): Observable<IEventCreate[]> {
    const checkBody: IEventCheckDate = {
      type,
      startDate: this.formatSingleDate(startDate, 'yyyy-MM-dd'),
      endDate: endDate ? this.formatSingleDate(endDate, 'yyyy-MM-dd') : '9999-12-31'
    };

    return this.eventService.checkEventDate(checkBody);
  }

  /**
   * Format tab subtitle based on params
   * @remarks 15.-30. Sep. 2021 (16 day)
   * @param startDate - Start date
   * @param endDate - End date
   */
  formatDateSubtitle(startDate: NgbDate | NgbDateStruct | undefined, endDate?: NgbDate | NgbDateStruct): string {
    if (startDate && endDate) {
      return `${this.formatDate(
        new Date(startDate.year, startDate.month - 1, startDate.day).toISOString(),
        new Date(endDate.year, endDate.month - 1, endDate.day).toISOString()
      )} (${this.differenceDate(
        new Date(startDate.year, startDate.month - 1, startDate.day).toISOString(),
        new Date(endDate.year, endDate.month - 1, endDate.day).toISOString()
      )} ${this.translateService.translate('day')})`;
    } else if (startDate)
      return `${this.formatDate(new Date(startDate.year, startDate.month - 1, startDate.day).toISOString())}`;
    else return '';
  }

  /**
   * Set wizard tab details
   * @param step One wizard tab
   * @param subtitle Subtitle for wizard
   * @param completed Icon for subtitle
   * @param formInputValid Icon for invalid wizard tab
   */
  setWizardCompleted(step: IWizardTabComponent, subtitle?: string, completed = true, formInputValid = true): void {
    step.completed = completed;
    step.formInputValid = formInputValid;

    if (subtitle !== undefined) {
      step.subtitle = subtitle;
    }

    step.notifier?.next();
  }

  onDateSelection(
    date: NgbDate,
    inputForm: string,
    form: UntypedFormGroup,
    dateInformation: IEventDate
  ): Observable<IEventCreate[]> {
    form.get(inputForm)?.setValue(date);
    if (inputForm === 'startDate') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      dateInformation.minDate = date;
      if (date.after(form.get('endDate')?.value as NgbDateStruct)) {
        form.get('endDate')?.setValue(date);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        dateInformation.to = this.formatSingleDate(date);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      dateInformation.from = this.formatSingleDate(form.get('startDate')?.value as NgbDate);
      return this.checkEventDate(
        TargetSender.SENDER,
        form.get('startDate')?.value as NgbDate,
        form.get('endDate')?.value as NgbDate
      );
    } else {
      form.get('endDate')?.setValue(date);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      dateInformation.to = this.formatSingleDate(form.get('endDate')?.value as NgbDate);
      return this.checkEventDate(
        TargetSender.SENDER,
        form.get('startDate')?.value as NgbDate,
        form.get('endDate')?.value as NgbDate
      );
    }
  }

  /**
   * Redirect user to old angular in new tab
   * @param integration - Type of integration
   * @param id
   */
  async redirectToOldAngular(integration: string, id?: number | string): Promise<boolean> {
    if (integration === 'dashboard') {
      window.open(
        `${environment.legacyUrl}/login?rurl=dashboard&ungated=true&email=${this.authService.getLocalStorage(
          MailtasticAuthorizationDetails.email
        )}`
      );
      return true;
    }

    // Open modal
    const modalRedirectWarningRef = this.modalService.open(DefaultSimpleMessageComponent, {
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal mt_modal_930'
    });

    const alertInfo = {
      bodyText: '',
      btnText: '',
      titleText: '',
      type: EBoxType.SUCCESS
    };

    switch (integration) {
      case 'editSignature':
        alertInfo.type = EBoxType.INFO;
        alertInfo.bodyText = this.translateService.translate('interim_signatures_modal_sl');
        alertInfo.btnText = this.translateService.translate('interim_signatgures_modal_btn1');
        alertInfo.titleText = this.translateService.translate('interim_login_signature_hl');
        break;
      case 'createSignature':
        alertInfo.bodyText = this.translateService.translate('html_sig_editor_old_app');
        alertInfo.btnText = this.translateService.translate('interim_signatgures_modal_btn3');
        alertInfo.titleText = this.translateService.translate('interim_login_signature_hl');
        break;
      case 'o365':
        alertInfo.bodyText = this.translateService.translate('interim_integrations_modal_sl');
        alertInfo.btnText = this.translateService.translate('Close');
        alertInfo.titleText = this.translateService.translate('interim_integrations_modal_hl');
        alertInfo.type = EBoxType.INFO;
        break;
      default:
        alertInfo.bodyText = this.translateService.translate('interim_integrations_modal_sl');
        alertInfo.btnText = this.translateService.translate('interim_integrations_modal_btn');
        alertInfo.titleText = this.translateService.translate('interim_integrations_modal_hl');
        break;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRedirectWarningRef.componentInstance['parent'] = alertInfo;

    const result = (await modalRedirectWarningRef.result) as boolean;

    if (result) {
      switch (integration) {
        case 'integrations':
          window.open(
            `${environment.legacyUrl}/login?rurl=integrations&email=${this.authService.getLocalStorage(
              MailtasticAuthorizationDetails.email
            )}`
          );
          break;
        case 'o365':
          modalRedirectWarningRef.close();
          break;
        case 'o365details':
          window.open(
            `${environment.legacyUrl}/login?rurl=o365details${
              id ? `&id=${id}` : ''
            }&email=${this.authService.getLocalStorage(MailtasticAuthorizationDetails.email)}`
          );
          break;
        case 'editSignature':
          if (environment.isCogSig) {
            window.open(
              `${environment.legacyUrl}/login?rurl=signature${
                id ? `&id=${id}` : ''
              }&email=${this.authService.getLocalStorage(MailtasticAuthorizationDetails.email)}`
            );
          } else {
            const accountData = this.accountService.getUserAccountData();
            window.open(
              `${environment.legacyUrl}/login?rurl=signature${
                id ? `&id=${id}` : ''
              }&email=${this.authService.getLocalStorage(MailtasticAuthorizationDetails.email)}&type=signature${
                accountData.activeAccountId ? `&aid=${accountData.activeAccountId}` : ''
              }`,
              '_self'
            );
          }
          break;
        case 'createSignature':
          if (environment.isCogSig) {
            window.open(
              `${environment.legacyUrl}/login?rurl=signaturecreate&email=${this.authService.getLocalStorage(
                MailtasticAuthorizationDetails.email
              )}`
            );
          } else {
            const accountData = this.accountService.getUserAccountData();
            window.open(
              `${environment.legacyUrl}/login?rurl=signaturecreate&email=${this.authService.getLocalStorage(
                MailtasticAuthorizationDetails.email
              )}&type=signature${accountData.activeAccountId ? `&aid=${accountData.activeAccountId}` : ''}`,
              '_self'
            );
          }
          break;
      }
    }

    return result;
  }

  /**
   * Used to remove http, https and/or www from the url
   * @param url - Url to remove http, https and/or www
   * @returns Url without http, https and/or www. Empty string if url is empty
   */
  removeHttpHttpsWww(url?: string): string {
    if (!url) {
      return '';
    }

    return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');
  }

  /**
   * Removes 'https://' and 'http://' from the beginning of the URL, leaving 'www' if present.
   * @param url - URL to process
   * @returns URL without 'https://', 'http://' prefixes. Returns an empty string if the URL is empty.
   */
  removeHttpsPrefix(url?: string): string {
    if (!url) {
      return '';
    }

    return url.replace(/^(?:https?:\/\/)/i, '');
  }

  /**
   * Checks if the url starts with http/https and attach when missing
   * @param url - Url to check
   * @returns Original url with http or url with https. Empty string if url is empty
   */
  checkAndAddHttps(url?: string): string {
    if (!url) {
      return '';
    }

    if (!url.match(/^(?:f|ht)tps?:\/\//i)) {
      url = 'https://' + url;
    }

    return url;
  }

  /**
   * Navigates to help center
   */
  navigateToHelp(): void {
    let link = '';
    if (this.translateService.getActiveLang() === 'de') {
      link = 'https://help.mailtastic.com/de/';
    } else if (this.translateService.getActiveLang() === 'en') {
      link = 'https://help.mailtastic.com/en/';
    } else {
      link = 'https://intercom.help/cognism-signatures/en/';
    }
    window.open(link);
  }

  /**
   * Given 2 generic arrays with an `id` property, return the intersection based on `id`
   * @param array1 - Array to check
   * @param array2 - Array to check
   * @returns Intersection array
   */
  intersectIds<T extends ID_OBJECT, U extends ID_OBJECT>(array1: T[], array2: U[]): (string | number)[] {
    // Create a Set from the ids in each array
    const set1 = new Set(array1.map(item => item.id));
    const set2 = new Set(array2.map(item => item.id));

    // Use the intersection function to get the intersection of the two sets
    const intersection = new Set([...set1].filter(x => set2.has(x)));

    // Convert the intersection set back to an array and return it
    return [...intersection];
  }

  /**
   * Opens a link in a new tab or in the same tab depending on the input
   * @param linkToOpen - Link to open
   * @param whereToOpen - Defines where to open the link (new tab or the same tab)
   */
  openLink(linkToOpen: string, whereToOpen: '_blank' | '_self'): void {
    window.open(linkToOpen, whereToOpen);
  }

  /**
   * Loader trigger
   */
  loadingStart(): void {
    this.document.body.classList.add('fullscreen-overlay-loader');
  }

  /**
   * Loader stop
   */
  loadingStop(): void {
    this.document.body.classList.remove('fullscreen-overlay-loader');
  }

  /**
   * Maps data from Object to Map<K,V>.
   * @param objectData -  Object data to map.
   * @remarks If objectData is of type string, JSON is assumed.
   * @returns Object as Map.
   */
  mapFromObject<K = string, V = any>(objectData: string | any): Map<K, V> {
    const parsedData = typeof objectData === 'string' ? JSON.parse(objectData) : objectData;
    const mapData = new Map<K, V>();
    for (const key in parsedData) {
      if (parsedData.hasOwnProperty(key)) {
        mapData.set(key as K, parsedData[key] as V);
      }
    }
    return mapData;
  }

  /**
   * Scales the image dimensions according to the `newWidth`.
   * @param origWidth - the orignial width
   * @param origHeight - the orignial height
   * @param newWidth - the new width to use as a reference
   * @returns the scaled dimensions
   */
  scaleImage(
    origWidth: number,
    origHeight: number,
    newWidth: number,
    mode = 'custom' as TImageDimensionDataMode
  ): ImageDimensionWithMode {
    // Number(*) required just to double check if actually a number
    if (Number(newWidth) === 0 || Number(origWidth) === Number(newWidth)) {
      // original height and width are used with mode=default,
      // this prevents using falsy data
      return {
        height: Number(origHeight),
        mode: 'default',
        width: Number(origWidth)
      };
    }
    const scale = Math.abs(origWidth / newWidth);
    return {
      height: Math.ceil(origHeight / scale),
      mode,
      width: Math.abs(newWidth)
    };
  }
}
