import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFullQueryObj, IQueryObj, ISortList, ESortOptions } from './query-helper-service.interface';

@Injectable({
  providedIn: 'root'
})
export class QueryHelperService {
  defaultVar: IFullQueryObj = {
    pagesize: 25,
    page: 0,
    action: '',
    search: '',
    sort: '',
    sortDir: 'ASC',
    sortList: ''
  };

  var = Object.assign({}, this.defaultVar);

  defSortList: ISortList = {
    employees: [ESortOptions.FIRST_NAME, ESortOptions.LAST_NAME, ESortOptions.EMAIL, ESortOptions.IS_OWA_ACTIVATED],
    groups: [ESortOptions.GROUP_TITLE],
    targetListEntries: [
      ESortOptions.CONTENT,
      ESortOptions.CLICKS,
      ESortOptions.VIEWS,
      ESortOptions.TG_TITLE,
      ESortOptions.PROSPECT
    ]
  };

  private _queryObject$ = new BehaviorSubject(this.getObject());
  queryObject$ = this._queryObject$.asObservable();

  /**
   * Creates a query from the keys sent
   * @param {IQueryObj} queryObj - query object holding the keys
   * @returns {string} search query or empty string
   */
  createQuery(queryObj?: IQueryObj): string {
    return queryObj ? this.createFullQuery(queryObj) : '';
  }

  /**
   * Creates a query from all possible values
   * @param {IQueryObj} queryObj - query object holding the keys
   * @returns {string} complete query string
   */
  createFullQuery(queryObj?: IQueryObj): string {
    let query = '?';
    const object = queryObj ? queryObj : this.var;

    // validating
    object.sort = this.isValidSort(object.sort, this.var.sortList);
    object.sortDir = object.sort ? object.sortDir : 'ASC';

    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const val = object[key];
        if (val || typeof val === 'number') {
          query += '&' + key + '=' + val;
        }
      }
    }

    if (query.length > 1) {
      query = query.replace('?&', '?');
    } else {
      query = '';
    }

    return query;
  }

  /**
   * Creates a query from only the search value
   * @param {Object} queryObj - query object holding the keys
   * @returns {string} search query or empty string
   */
  createQuerySearch(queryObj?: IQueryObj): string {
    return queryObj && queryObj.search ? '?search=' + queryObj.search : '';
  }

  /**
   * Flips the direction of the sorting. The input can either be a boolean or the key-word.
   * Settings: ASC === true; DESC === false;
   * @param {string | boolean} sortDir - 'ASC' | true
   * @returns {string} 'DESC' | 'ASC'
   */
  flipSortDirection(sortDir: string | boolean): string {
    if (sortDir === true || sortDir === 'ASC') return 'DESC';
    return 'ASC';
  }

  /**
   * Returns the default object with all variables if empty param. Else returns the object based off of specified keys in the array.
   * @param {string[]} optionArray - array holding keys
   * @returns {IQueryObj} Object holding query params filtered over the `optionArray`
   */
  getObject(): IQueryObj {
    return this.getOutputObject();
  }

  /**
   * Returns the current values for query params and goes to the next page of results
   * @returns {IQueryObj} Object holding query params
   */
  getObjectAndGoNext(): IQueryObj {
    const output = this.getOutputObject();
    this.getNextPage();
    return output;
  }

  /**
   * Returns the current values for query params
   * @returns {IQueryObj} Object holding query params
   */
  getOutputObject(): IQueryObj {
    return {
      page: this.var.page,
      pagesize: this.var.pagesize,
      search: this.var.search,
      sort: this.var.sort,
      sortDir: this.var.sortDir
    };
  }

  /**
   * Searches through the whole database
   * @param {string} search - terms to search
   */
  getSearchAll(search: string): void {
    this.var.search = search;

    // Removes page restrictions
    this.var.page = 999999;
    this.var.pagesize = 999999;
    this.var.action = '';
  }

  /**
   * Get the next page of results
   */
  getNextPage(): void {
    ++this.var.page;
  }

  /**
   * Get limited number of entries
   * @param pageSize - size of the entries
   * @returns query object with given page size
   */
  getLimitedPageEntries(pageSize: number): IQueryObj {
    const output = this.getOutputObject();
    output.pagesize = pageSize;
    return output;
  }

  /**
   * Resets `page` and `pagesize` to default values
   */
  resetPagination(): void {
    this.var.page = this.defaultVar.page;
    this.var.pagesize = this.defaultVar.pagesize;
  }

  /**
   * Updates the search with the provided value
   * @param {string} search New search to set
   */
  setSearch(search: string): void {
    this.var.search = search;
  }

  /**
   * Sets the service `sort` and `sortDir` from the input
   * @param mode New sort mode to set
   * @param sortDir New sort dir to set
   */
  setQuerySort(mode: string, sortDir?: boolean): void {
    this.var.sort = mode;
    this.var.sortDir = sortDir ? 'DESC' : 'ASC';
  }

  /**
   * Checks if one can sort the `sortValue` is allowed on the list `listName`, which are both defined within the service.
   * @private
   * @param {string} sortValue the value that should be sorted onto the `listName`
   * @param {"employees"|"groups"|"targetListEntries"} listName
   * @returns {string} `sortValue` or empty string
   */
  private isValidSort(sortValue: string, listName: string): string {
    if (!this.defSortList[listName] && listName) {
      console.error(`listName:${listName} does not exist! Available lists are ${this.defSortList.toString()}`);
      // listName = null;
    }
    const tSortList = !this.defSortList[listName] ? this.defSortList.employees : this.defSortList[listName];

    return !(tSortList || []).includes(sortValue) ? '' : sortValue;
  }

  /**
   * Checks if query sorting should be applied.
   * @param {string} sortValue
   * @param {string} listName
   * @returns {string} `sortValue` or empty string
   */
  checkQuerySort(sortValue: string, listName: string): string {
    return this.isValidSort(sortValue, listName);
  }

  /**
   * Sets the new value for query object and triggers new data load
   * @param queryObj - Object to set as new value
   */
  setQueryObservable(queryObj: IQueryObj): void {
    this._queryObject$.next(queryObj);
  }

  /**
   * Sets the query object to the next page and triggers data load
   */
  loadNextPage(): void {
    this.getNextPage();
    this.setQueryObservable(this.getObject());
  }
}
