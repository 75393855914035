import { Injectable } from '@angular/core';
import { compareAsc, compareDesc, parseISO } from 'date-fns';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

import { ICampaignStatus, ISignatureStatus } from '../signature-campaign-status/signature-campaign-status.service';
import { UtilService } from '../util/util.service';
import { GroupSmall } from '../../model/interfaces/group.interface';
import { IActiveGroups } from '../signature/signature-service.interface';
import {
  ID_OBJECT,
  IListSortDirection,
  LIST_TYPE,
  SELECT_TYPE,
  SORT_COLUMNS,
  TListSortMode
} from './list-helper-service.interface';
import { ITargetGroupListEntry } from 'src/app/model/interfaces/target-group-list.interface';
import { IUser } from 'src/app/model/interfaces/user.interface';
import { EventDataService } from '@services/event-data/event-data.service';
import { EventStatus } from '@model/enums/event-status.enum';

@Injectable({
  providedIn: 'root'
})
export class ListHelperService<ArrayType extends ID_OBJECT & SORT_COLUMNS> {
  all = [] as ArrayType[];
  filtered = [] as ArrayType[];
  selected = [] as ArrayType[];
  filterText = '';

  // Used to cycle through status position in its array
  private statusIndex = 0;

  // Observable for filter text
  // Waits 400ms and emits only distinct values
  private _filterText$ = new Subject<string>();
  filterText$ = this._filterText$.asObservable().pipe(debounceTime(400), distinctUntilChanged());

  hoveredItem = null;
  // TODO implement the two different select types
  selectionType: SELECT_TYPE = 'multi';
  listType: LIST_TYPE = 'sync';
  totalCount = 0; // helps to decide if a backend call is needed

  //used to display a fraction of all users: 'loaded/all'
  denominator = 0;
  fraction = '';

  sortDir: IListSortDirection = {
    activegroups: false,
    campaignStatus: false,
    CampaignTitle: false,
    clickRate: false,
    clicks: false,
    content: false,
    createdAt: false,
    disableAppleMailSync: false,
    disableOutlookMacSync: false,
    displayName: false,
    domain: false,
    duration: false,
    email: false,
    endDate: false,
    Entries: false,
    error: false,
    EventDuration: false,
    EventTitle: false,
    EventTriggerCampaignTitle: false,
    firstname: false,
    Groups: false,
    groups: false,
    imported: false,
    isNotYetImported: false,
    isProspect: false,
    isSyncActivated: false,
    LastClickCampaign: false,
    lastname: false,
    lastRollout: false,
    mtUser: false,
    name: false,
    programName: false,
    signatureStatus: false,
    signatureTitle: false,
    size: false,
    startDate: false,
    status: false,
    TargetGroupList: false,
    title: false,
    TriggerCampaignTitle: false,
    TriggerTargetTitle: false,
    type: false,
    views: false,
    workspaceName: false
  };

  customFilter = null;

  constructor(private utilService: UtilService, private eventDataService: EventDataService) {}

  /**
   * Creates a fraction as a string in the form of `numerator`/`denominator`
   * @param numerator - Fraction numerator
   * @param denominator - Fraction denominator
   */
  createFraction(numerator: number, denominator: number = this.denominator): void {
    this.fraction = numerator.toString() + '/' + denominator.toString();
  }

  setFiltered(): void {
    if (!this.filterText) {
      this.filtered = this.all;
    } else {
      this.filtered = this.all.filter(item => this.matchesFilter(item, this.filterText));
    }
  }

  /**
   * Dynamically checks if an item matches the filter text accross all the properties
   * @param item - The item that needs to be checked
   * @param filterText - The text to filter
   */
  private matchesFilter(item: object, filterText: string): boolean {
    return Object.values(item).some(value => {
      if (typeof value === 'string') {
        return value.toLowerCase().includes(filterText.toLowerCase());
      }
      return false;
    });
  }

  /**
   * @deprecated Use searchService.setFilterText instead
   * Sets the new value for text filtered
   * @param text - String to set as text filtered
   */
  setFilterText(text: string): void {
    this._filterText$.next(text);
  }

  /**
   * Checks if all items are selected
   * @returns Whether all items are selected
   */
  isSelectedAll(): boolean {
    return this.all.length > 0 && this.selected && this.selected.length === this.all.length;
  }

  /**
   * Checks if at least one item is selected
   * @returns Whether at lest one item is selected
   */
  isSelectedMany(): boolean {
    return this.selected.length > 0;
  }

  /**
   * Checks if one particular item is selected
   * @param item - item to check selection status
   * @returns Whether item is selected
   */
  isSelected<T extends ID_OBJECT>(item: T): boolean {
    const foundItem = this.selected.find(found => found.id === item.id);

    return !!foundItem;
  }

  /**
   * Checks if no items are selected
   * @returns Whether no items are selected
   */
  isSelectedNone(): boolean {
    return this.selected.length === 0;
  }

  /**
   * Updates an item's selected status
   * @param item - item to update
   */
  updateSelection(item: ArrayType): void {
    const newSelectedState = !this.isSelected(item);

    // Check if the item is already selected
    const index = this.selected.indexOf(item);

    if (this.selectionType === 'single') {
      // For single select if user select same option again then it should be de-selected
      this.selected = [];
      if (index === -1) {
        this.selected.push(item);
      }
      return;
    }

    // Add the item to the selected list if it's not present
    if (newSelectedState && index === -1) {
      this.selected.push(item);
    }

    // Remove the item from the selected list if it's present
    if (!newSelectedState && index !== -1) {
      this.selected.splice(index, 1);
    }
  }

  /**
   * Updates the selection status of all filtered items
   */
  updateSelectionAll(): void {
    const shouldSelectAll = this.selected.length !== this.filtered.length;

    if (shouldSelectAll) {
      this.selected = [...this.selected, ...this.filtered.filter(item => !this.isSelected(item))];
    } else {
      this.selected = [];
    }
  }

  /**
   * Sorts the list based off of the `mode`.
   * @param mode - what should be sorted
   * @param altList - alternate list over `all`
   * @returns the sorted list
   */
  sortList(mode: TListSortMode, altList = this.all): ArrayType[] {
    // Helper function to handle cases of 0 clicks/views
    const calcRate = (a?: number, b?: number): number => {
      if (!a || !b || a === 0 || b === 0) {
        return 0;
      }

      return a / b;
    };

    const MAX_INT = 100000;
    let x, y, ii, jj;
    const sortedList = altList.sort((a, b) => {
      let res;
      switch (mode) {
        case 'isNotYetImported':
          x = (a[mode] as boolean) || false;
          y = (b[mode] as boolean) || false;
          res = this.sortDir[mode] ? Number(x) - Number(y) : Number(y) - Number(x);
          break;
        case 'isSyncActivated':
          x = (a[mode] as boolean) || a.mtUser?.isSyncActivated || false;
          y = (b[mode] as boolean) || b.mtUser?.isSyncActivated || false;
          res = this.sortDir[mode] ? Number(x) - Number(y) : Number(y) - Number(x);
          break;
        case 'CampaignTitle':
          x = (a.Campaign?.title as string) || '';
          y = (b.Campaign?.title as string) || '';

          res = this.sortDir[mode] ? x.localeCompare(y) : y.localeCompare(x);
          break;
        case 'TargetGroupList':
          x = (a.TargetGroupList?.title as string) || (a.targetGroupList?.title as string) || '';
          y = (b.TargetGroupList?.title as string) || (b.targetGroupList?.title as string) || '';

          res = this.sortDir[mode] ? x.localeCompare(y) : y.localeCompare(x);
          break;
        case 'LastClickCampaign':
          x = (a.lastClickCampaign?.title as string) || '';
          y = (b.lastClickCampaign?.title as string) || '';

          res = this.sortDir[mode] ? x.localeCompare(y) : y.localeCompare(x);
          break;
        case 'EventTitle':
          x = (a.Event?.title as string) || '';
          y = (b.Event?.title as string) || '';

          res = this.sortDir[mode] ? x.localeCompare(y) : y.localeCompare(x);
          break;
        case 'EventTriggerCampaignTitle':
          x = (a.Event?.TriggerCampaign?.title as string) || '';
          y = (b.Event?.TriggerCampaign?.title as string) || '';

          res = this.sortDir[mode] ? x.localeCompare(y) : y.localeCompare(x);
          break;
        case 'EventDuration':
          x = this.utilService.differenceDate(a.Event?.startDate, a.Event?.endDate);
          y = this.utilService.differenceDate(b.Event?.startDate, b.Event?.endDate);

          res = this.sortDir[mode] ? compareAsc(x, y) : compareDesc(x, y);
          break;
        case 'TriggerCampaignTitle':
          x = a.TriggerCampaign?.title as string;
          y = b.TriggerCampaign?.title as string;

          res = this.sortDir[mode] ? x.localeCompare(y) : y.localeCompare(x);
          break;
        case 'TriggerTargetTitle':
          // NOTE : This list contains target groups & departments both at a time in a list & based on their title sorting should be perform
          // It will check for target groups first, if not available then it will check for departments
          if (a.TriggerTargetGroups?.length) {
            x = a.TriggerTargetGroups[0]?.TargetGroupList?.title ?? '';
          } else if (a.TriggerGroups?.length) {
            x = a.TriggerGroups[0]?.Group.title ?? '';
          } else {
            x = '';
          }

          if (b.TriggerTargetGroups?.length) {
            y = b.TriggerTargetGroups[0]?.TargetGroupList?.title ?? '';
          } else if (b.TriggerGroups?.length) {
            y = b.TriggerGroups[0]?.Group.title ?? '';
          } else {
            y = '';
          }
          res = this.sortDir[mode] ? x.localeCompare(y) : y.localeCompare(x);
          break;
        case 'duration':
          x = this.utilService.differenceDate(a.startDate, a.endDate);
          y = this.utilService.differenceDate(b.startDate, b.endDate);

          res = this.sortDir[mode] ? compareAsc(x, y) : compareDesc(x, y);
          break;
        case 'endDate':
        case 'createdAt':
        case 'lastRollout':
          x = a[mode] ? parseISO((a[mode] as Date).toString()) : 0;
          y = b[mode] ? parseISO((b[mode] as Date).toString()) : 0;
          res = this.sortDir[mode] ? compareAsc(x, y) : compareDesc(x, y);
          break;
        case 'content':
        case 'displayName':
        case 'domain':
        case 'email':
        case 'firstname':
        case 'lastname':
        case 'name':
        case 'title':
        case 'type':
          x = (a[mode] as string) ?? '';
          y = (b[mode] as string) ?? '';
          res = this.sortDir[mode] ? x.localeCompare(y) : y.localeCompare(x);
          break;
        case 'signatureTitle':
          x = a[mode] as string;
          y = b[mode] as string;
          res = this.sortDir[mode]
            ? x?.toString().localeCompare(y?.toString())
            : y?.toString().localeCompare(x?.toString());
          break;
        case 'campaignStatus':
        case 'signatureStatus':
          x = a[mode] as ICampaignStatus | ISignatureStatus;
          y = b[mode] as ICampaignStatus | ISignatureStatus;
          ii = this.calcStatus(x);
          jj = this.calcStatus(y);
          res = this.sortDir[mode] ? jj - ii : ii - jj;
          break;
        case 'mtUser':
          x = a[mode] as IUser;
          y = b[mode] as IUser;
          res = this.sortDir[mode] ? Number(!x) - Number(!y) : Number(!y) - Number(!x);
          break;
        case 'Entries':
          x = a[mode] as ITargetGroupListEntry[];
          y = b[mode] as ITargetGroupListEntry[];
          res = this.sortDir[mode] ? x.length - y.length : y.length - x.length;
          break;
        case 'activegroups':
        case 'Groups':
          x = a[mode] as (IActiveGroups | GroupSmall)[];
          y = b[mode] as (IActiveGroups | GroupSmall)[];
          res = this.sortDir[mode] ? x.length - y.length : y.length - x.length;
          break;
        case 'clicks':
        case 'views':
        case 'size':
          x = a[mode] as number;
          y = b[mode] as number;
          res = this.sortDir[mode] ? x - y : y - x;
          break;
        case 'clickRate':
          res = this.sortDir[mode]
            ? calcRate(a.clicks, a.views) - calcRate(b.clicks, b.views)
            : calcRate(b.clicks, b.views) - calcRate(a.clicks, a.views);
          break;
        case 'disableOutlookMacSync':
        case 'disableAppleMailSync':
        case 'isProspect':
          x = a[mode] as boolean;
          y = b[mode] as boolean;
          res = this.sortDir[mode] ? Number(x) - Number(y) : Number(y) - Number(x);
          break;
        case 'status':
          // should use function `sortListByStatus` instead
          x = a[mode] as boolean;
          y = b[mode] as boolean;
          res = this.sortDir[mode] ? Number(x) - Number(y) : Number(y) - Number(x);
          break;
        case 'error':
          x = a[mode];
          y = b[mode];
          res = x === y ? 0 : x ? -1 : 1;
      }
      return typeof res === 'number' ? res : MAX_INT;
    });

    // flips the sorting direction
    this.sortDir[mode] = !this.sortDir[mode];
    return sortedList;
  }

  /**
   * Sorts the list based off of the `status`.
   * @param altList - alternate list over `all`
   * @param statusArray - the status array to cycle through ordered via importance
   * @returns the sorted list
   */
  sortListByStatus<T>(altList = this.all, statusArray = ['live', 'upcoming', 'end', 'draft']): ArrayType[] {
    function shiftArrayBy<T>(array: T[], x: number): T[] {
      const normalizedShift = x % array.length;
      const shiftedPart = array.slice(0, normalizedShift);
      const remainingPart = array.slice(normalizedShift);
      return remainingPart.concat(shiftedPart);
    }

    // used to shift the array by an amount
    const currentIndex = this.statusIndex % statusArray.length;

    // permutates the statusArray so that currentIndex is the head of the array
    const sortOrderArray = shiftArrayBy<T>(statusArray as T[], currentIndex);

    // sorts the list.all
    const sortedList = altList
      .map(item => {
        // the status one sees on UI
        const uiStatus = this.eventDataService.getEventStatus(
          item.startDate,
          item.endDate,
          item.status as EventStatus
        ) as T;
        // sets the sort order location
        const orderLocation = statusArray.length - sortOrderArray.indexOf(uiStatus);

        return {
          ...item,
          orderLocation
        };
      })
      .sort((a, b) => b.orderLocation - a.orderLocation);

    // increments to sort with next item in statusArray
    this.statusIndex++;
    return sortedList;
  }

  //#region PRIVATE
  /**
   * Sorts an array in reference to its `campaignStatus|signatureStatus`.
   * By depicting the position of its items.
   *
   * @private
   * @param item - length of the array
   * @returns the position of where the `item` should be
   */
  private calcStatus(item: ICampaignStatus | ISignatureStatus): number {
    let length = 0;
    if (this.isSignatureStatus(item)) {
      length = item.signatures.length;
    } else if (this.isCampaignStatus(item)) {
      length = item.campaigns.length;
    }

    if (length === 0) {
      return 0;
    } else if (item.atLeastOneNotLatest === true) {
      return 1;
    } else {
      // atLeastOneNotLatest === false
      return 2;
    }
  }

  /**
   * Check if field is of type ICampaignStatus
   *
   * @private
   * @param variableToCheck - Field to check
   * @returns
   */
  isCampaignStatus(variableToCheck: unknown): variableToCheck is ICampaignStatus {
    return (
      (variableToCheck as ICampaignStatus).campaigns &&
      (variableToCheck as ICampaignStatus).atLeastOneNotLatest !== undefined
    );
  }

  /**
   * Check if field is of type ISignatureStatus
   *
   * @private
   * @param variableToCheck - Field to check
   * @returns
   */
  isSignatureStatus(variableToCheck: unknown): variableToCheck is ISignatureStatus {
    return (
      (variableToCheck as ISignatureStatus).signatures &&
      (variableToCheck as ISignatureStatus).atLeastOneNotLatest !== undefined
    );
  }

  /**
   * Resets the array data structures to their initial values
   */
  resetData(): void {
    this.all = [];
    this.selected = [];
    this.filtered = [];
  }
  //#endregion
}
