import { Pipe, PipeTransform } from '@angular/core';
import { isBefore, parseISO, isDate } from 'date-fns';
import { ISignature } from 'src/app/model/interfaces/signature.interface';

/**
 * Determines if the signature is in rolled out status or not
 * @returns Boolean indicating the signature is rolled or not/changes were made since
 */

@Pipe({
  name: 'signatureRolledoutStatus',
  standalone: true
})
export class SignatureRolledoutStatusPipe implements PipeTransform {
  transform(signature: ISignature): boolean {
    // never rolledout
    if (!signature.lastRollout) {
      return false;
    }

    // determine rollout status of signature
    const lastRollOut = parseISO(this.getDateISOString(signature.lastRollout));

    const lastCompanyInfoChange = parseISO(this.getDateISOString(signature.companyInfoUpdatedAt));
    const lastSignatureChanged = parseISO(this.getDateISOString(signature.signatureUpdatedAt));
    // if the rollout was before last company data change or before last signature change
    return !(isBefore(lastRollOut, lastCompanyInfoChange) || isBefore(lastRollOut, lastSignatureChanged));
  }

  /**
   * Gets date string
   * @param date - The date
   * @returns The date in string
   */
  getDateISOString(date: string | Date | undefined): string {
    if (!date) {
      return new Date(0).toISOString();
    } else if (isDate(date)) {
      return (date as Date).toISOString();
    } else {
      return date as string;
    }
  }
}
