import { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iif, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ESignatureType } from 'src/app/model/enums/signature-type.enum';
import {
  CountWithoutResponse,
  DataOrMessage,
  DataOrSuccess,
  IResponse,
  IResponseCount,
  IResponseData,
  IResponseMessage,
  IResponseMessageStatus,
  Response,
  ResponseData,
  ResponseMessageOrCode
} from 'src/app/model/interfaces/response.interface';
import {
  ISignaturePlaceholderValueImg,
  SignaturePreviewType,
  TSignaturePlaceholder,
  TSignaturePlaceholderValue
} from 'src/app/model/interfaces/signature.interface';
import { IUser, IUserSmall } from 'src/app/model/interfaces/user.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert/alert.service';
import { AuthService } from '../auth/auth.service';
import { IntercomService, INTERCOM_DATA } from '../intercom/intercom.module';
import { IQueryObj } from '../query-helper/query-helper-service.interface';
import { QueryHelperService } from '../query-helper/query-helper.service';
import {
  AzureEmployeeImportData,
  EmployeeDefaultSignatures,
  EmployeeDetails,
  EmployeeGetUploadedImageData,
  EmployeeGroupsBasicData,
  EmployeeImportedData,
  EmployeeFullDetails,
  IEmployeeAdd,
  IEmployeeAddParam,
  IEmployeeGet,
  IEmployeeGetEmployeesNotMemberOfGroup,
  IEmployeeGetOne,
  IEmployeeGetUsersByEmail,
  IEmployeeGetWithImpressionsAndClicks,
  IResponseSavedObject,
  IResponseSetEmployeeInfoSingle,
  OutdatedSyncGet,
  SyncProblemListFilterOptions,
  UpdateEasyMacSyncDTO,
  EmployeeForSigPreview
} from './employee-service.interface';
import { INavigationSidebarSubmenuCount } from 'src/app/modules/root/model/navigation-sidebar-menu.interface';
import { NavigationSidebarService } from '../navigation-sidebar/navigation-sidebar.service';
import { ResponseGetIntegrationData, EmployeeDataset } from 'src/app/services/employee/employee-service.interface';
import {
  addGoogleUsersResponse,
  googleUser,
  importGsuiteUserAddParam
} from 'src/app/services/google-sync/google-sync.interface';
import { IDataset } from 'src/app/model/interfaces/dataset.interface';
import { EmpDataFields, EmpImportDataMappingFields } from 'src/app/model/interfaces/data-mapping.interface';
import { MailtasticAuthorizationDetails } from 'src/app/model/enums/mailtastic-authorization-details.enum';
import { O365Users } from 'src/app/services/azure-sync/azure-sync.interface';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  constructor(
    private alert: AlertService,
    private authService: AuthService,
    private http: HttpClient,
    private intercomService: IntercomService,
    private navigationSidebarService: NavigationSidebarService,
    private operator: CustomOperators,
    private queryHelperService: QueryHelperService,
    private translate: TranslocoService
  ) {}

  counter: INavigationSidebarSubmenuCount = {
    campaigns: 0,
    departments: 0,
    employees: 0,
    employeesOutdated: 0,
    events: 0,
    notifications: 0,
    signatures: 0,
    targetGroups: 0
  };

  /**
   * Holds the default filter options for sync problem list
   */
  private readonly defaultFilterOptions = {
    easySyncVersion: false,
    lastChromePluginSync: false,
    lastOutlookAddInSync: false,
    lastOutlookEasySync: false,
    noSyncSetUpYet: false
  };

  /**
   * Activates the employee.
   * @param params -
   * @returns
   */
  activateEmployee(params: unknown): Observable<IResponseMessage> {
    // TODO add authorization: false
    return this.http
      .post<IResponseMessage>('/account/activate/employee', params)
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * Creates a new employee.
   * @param employee - New employee
   * @returns
   */
  add(employee: IEmployeeAddParam): Observable<string> {
    return this.http.post<IEmployeeAdd>('/employees', employee).pipe(
      map(value => {
        if (!value.success) {
          if (value.message.includes('employee already existing')) {
            throw new Error(this.alert.translate('EMPLOYEE_MAIL_ALREADY_EXISTING'));
          } else {
            throw new Error(this.alert.translateDataNotLoaded());
          }
        }
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.employee_added);
        this.navigationSidebarService.updateSidebarSubmenuCounter(1, 'employees');
        this.navigationSidebarService.updateSidebarSubmenuCounter(1, 'employeesOutdated');
        if (employee.email === this.authService.getLocalStorage(MailtasticAuthorizationDetails.email)) {
          this.authService.updateUserIdToAuthScope(value.id);
        }
        return value.id;
      }),
      catchError((err: HttpErrorResponse) => {
        throw err;
      })
    );
  }

  /**
   * Adds employees for Azure Sync.
   * @param data -
   * @param useGroupOnImport -
   * @returns
   */
  addAzureSyncedUsers(data: O365Users[], useGroupOnImport: boolean, syncInfoId: number): Observable<O365Users[]> {
    return this.http
      .post<AzureEmployeeImportData>('/employees/manySyncUsers', {
        empsasjson: data,
        type: 'azure',
        useGroupOnImport,
        syncInfoId: syncInfoId
      })
      .pipe(
        map(value => {
          if (!value.success) {
            new Error(this.alert.translateDataNotLoaded());
            return [] as O365Users[];
          }
          return value.empsAdded;
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Add users which will be synced with gmail apps wor work
   * @param data - The users data to import
   * @param googleToken - The verification google token
   * @returns The added users data
   */
  addGoogleSyncedUsers(data: importGsuiteUserAddParam[], googleToken: string): Observable<googleUser[]> {
    return this.http
      .post<addGoogleUsersResponse>('/employees/manySyncUsers', {
        empsasjson: data,
        googleToken: JSON.parse(googleToken)
      })
      .pipe(
        map(value => {
          if (!value.success && value?.code && value.code !== 76) {
            void this.alert.defaultErrorMessage(this.alert.translate('DATEN_NICHT_GELADEN'));
            throw new Error(this.alert.translateDataNotLoaded());
          }
          return value.empsAdded || [];
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Add many employees to the group.
   * @param data - The uploaded file data
   * @param datasets - The selected datasets array
   * @param useGroupsFromImportFile - Holds the trigger to get the department assignment from uploaded file or selected one
   * @param group - The group id where employees should be import
   * @returns The added employees ids
   */
  addMany(
    data: EmpDataFields[],
    useGroupsFromImportFile: boolean,
    datasets: IDataset[],
    group?: number | string
  ): Observable<EmployeeImportedData> {
    return this.http
      .post<EmployeeImportedData>('/employees/many', {
        empsasjson: data,
        group: group,
        useGroupsFromImportFile,
        datasets
      })

      .pipe(
        map(value => {
          if (value.success) {
            return value;
          } else {
            if (value.code === 76) {
              //all adresses were already existing
              void this.alert.defaultErrorMessage(this.alert.translate('EXCEL_ALL_EMAIL_EXISTENT'));
            } else {
              //simply error
              void this.alert.defaultErrorMessage(this.alert.translate('EXCEL_IMPORT_UNSUCCESSFUL'));
            }
            throw new Error(this.alert.translateDataNotLoaded());
          }
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Update the assigned group to a user for individual default signatures
   * @param signatureType - Type of email to update
   * @param employeeId - User to update
   * @param groupId - Group to assign
   * @returns Observable of success message
   */
  assignIndividualSignature(
    signatureType: ESignatureType,
    employeeId: string,
    groupId: number
  ): Observable<IResponseMessage> {
    let emailType = '';
    switch (signatureType) {
      case ESignatureType.NEW_MAIL:
        emailType = 'newmail';
        break;
      case ESignatureType.REPLY_FORWARD:
        emailType = 'replyforward';
        break;
      case ESignatureType.INTERNAL_MAIL:
        emailType = 'internalMail';
        break;
    }

    return this.http
      .put<IResponseMessage>(`/employees/modify/individualgroup/${emailType}`, {
        employeeId,
        groupId
      })
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * Remove the assigned group to a user for individual default signatures
   * @param signatureType - Type of email to update
   * @param employeeId - User to update
   * @returns Observable of success
   */
  unassignIndividualGroup(signatureType: ESignatureType, employeeId: string): Observable<true> {
    let emailType = '';
    switch (signatureType) {
      case ESignatureType.NEW_MAIL:
        emailType = 'newmail';
        break;
      case ESignatureType.REPLY_FORWARD:
        emailType = 'replyforward';
        break;
      case ESignatureType.INTERNAL_MAIL:
        emailType = 'internalMail';
        break;
    }

    return this.http
      .put<IResponseMessage>(`/employees/unassign/individualgroup/${emailType}`, { employeeId })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Assign one or many employee ids to group with given id
   * @param employeeId -
   * @param groupIdToReplace -
   * @param groupIdToAssign -
   * @returns
   */
  changeGroup(
    employeeId: string,
    groupIdToReplace: number,
    groupIdToAssign: number
  ): Observable<IResponse | IResponseMessage> {
    return this.http
      .put<IResponse | IResponseMessage>('/employees/change/groups', {
        employeeId,
        groupIdToReplace,
        groupIdToAssign
      })
      .pipe(this.operator.extractResponseOrMessage());
  }

  /**
   * Check which of the given mail addresses are already existing thus can not be imported or created
   * @param adresses - The array of email addresses
   * @returns The array existing email address
   */
  checkExisting(adresses: string[]): Observable<{ email: string }[]> {
    return this.http
      .post<IResponseData<{ email: string }[]>>('/employees/checkexisting', { adresses })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Check if a single email address exists.
   * @param email -
   * @returns
   */
  checkExistingSingle(email: string): unknown {
    return this.http.get(`/employees/checkexisting/${email}`).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Check empid and activation code return all necessary data to show integration page
   * @param empId - The user id
   * @param activationCode - The user activation code
   * @param data - The base dataset detail
   * @param generalData - User detais
   * @returns - The response status
   */
  complementUserInfoData(
    empId: string,
    activationCode: string,
    data: EmployeeDataset[],
    generalData: IUserSmall
  ): Observable<boolean> {
    return this.http
      .post<IResponse>('/account/employee/complementdata/' + empId + '/' + activationCode, {
        fields: data,
        generalData
      })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Gets the count of employees.
   * @param queryObj - query object holding the keys or empty
   * @returns Count of employees that exist
   */
  count(queryObj?: IQueryObj): Observable<number> {
    return this.http
      .get<IResponseCount>(`/employees/count${this.queryHelperService.createQuerySearch(queryObj)}`)
      .pipe(this.operator.extractResponseCount());
  }

  /**
   * Updates the filter settings of outdated employees
   * @param filterOptions - The selected filter options
   * @returns The success
   */
  updateOutdatedFilterSettings(filterOptions = this.defaultFilterOptions): Observable<SyncProblemListFilterOptions> {
    return this.http
      .put<DataOrSuccess<SyncProblemListFilterOptions>>(`/v2/employees/outdated/filter/settings`, { filterOptions })
      .pipe(
        map(value => {
          if (!value.data) {
            throw new Error(this.alert.translateTechnicalError());
          }
          return value.data;
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Gets the filter settings of outdated employees
   * @returns The filter settings
   */
  getOutdatedFilterSettings(): Observable<SyncProblemListFilterOptions> {
    return this.http.get<DataOrSuccess<SyncProblemListFilterOptions>>(`/v2/employees/outdated/filter/settings`).pipe(
      map(value => {
        if (!value.data) {
          throw new Error(this.alert.translateTechnicalError());
        }
        return value.data;
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Gets the count of outdated employees.
   * @param filterOptions - The selected filter options
   * @returns Count of outdated employees that exist
   */
  countOutdatedEmployees(filterOptions?: SyncProblemListFilterOptions): Observable<number> {
    const query = filterOptions ? `?filterData=${JSON.stringify(filterOptions)}` : '';

    return this.http
      .get<CountWithoutResponse>(`/v2/employees/outdated/count${query}`)
      .pipe(this.operator.extractCount());
  }

  /**
   * Gets the count of employees **not** using apple mail sync.
   * @returns Count of employees **not** using apple mail sync.
   */
  countEasySyncMacApple(): Observable<number> {
    return this.http
      .get<IResponseCount>('/employees/count/easysyncmac/apple')
      .pipe(this.operator.extractResponseCount());
  }

  /**
   * Gets the count of employees **not** using outlook mac mail sync.
   * @returns Count of employees **not** using outlook mac mail sync.
   */
  countEasySyncMacOutlook(): Observable<number> {
    return this.http
      .get<IResponseCount>('/employees/count/easysyncmac/outlook')
      .pipe(this.operator.extractResponseCount());
  }

  /**
   * Creates a company and user account in Intercom
   * @param body -
   * @returns
   */
  createUserAndCompanyInIntercom(body: unknown): unknown {
    return this.http
      .put('/employees/intercom/createUserAndCompanyInIntercom', body)
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Deletes the employee.
   * @param id - employee id
   * @returns Observable of true
   */
  delete(id: string): Observable<IResponseMessage> {
    return this.http.delete<IResponseMessage>(`/employees/${id}`).pipe(
      this.operator.extractResponseMessage(),
      tap(() => {
        if (environment.isCogSig) {
          this.intercomService.trackEvent(INTERCOM_DATA.employee_removed);
        }
        if (id === this.authService.getLocalStorage(MailtasticAuthorizationDetails.userId)) {
          this.authService.updateUserIdToAuthScope();
        }
        this.navigationSidebarService.updateSidebarSubmenuCounter(-1, 'employees');
      }),
      switchMap(data =>
        combineLatest([of(data), iif(() => !environment.isCogSig, this.countOutdatedEmployees(), of(0))])
      ),
      map(([data, count]) => {
        this.navigationSidebarService.updateSidebarSubmenuCounter(count, 'employeesOutdated');
        return data;
      })
    );
  }

  /**
   * Deletes many employees.
   * @param ids - list of employee ids
   * @returns Observable of true
   */
  deleteMany(ids: string[]): Observable<IResponseMessage> {
    return this.http.post<IResponseMessage>('/employees/del/many', { empids: ids }).pipe(
      this.operator.extractResponseMessage(),
      tap(() => {
        const userId = this.authService.getLocalStorage(MailtasticAuthorizationDetails.userId);
        this.navigationSidebarService.updateSidebarSubmenuCounter(-ids.length, 'employees');
        if (userId && ids.find(id => id === userId)) {
          this.authService.updateUserIdToAuthScope();
        }
      }),
      switchMap(data =>
        combineLatest([of(data), iif(() => !environment.isCogSig, this.countOutdatedEmployees(), of(0))])
      ),
      map(([data, count]) => {
        this.navigationSidebarService.updateSidebarSubmenuCounter(count, 'employeesOutdated');
        return data;
      })
    );
  }

  /**
   * Deletes the employee's image.
   * @param employeeId -
   * @param datasetId -
   * @returns
   */
  deleteUserImage(employeeId: string, datasetId: string): Observable<true> {
    return this.http
      .get<IResponseSavedObject>('/employees/deleteuserimage/' + employeeId + '/' + datasetId)
      .pipe(this.operator.extractResponse());
  }

  /**
   * Delete the employee images in the profile.
   * @param datasetId - Dataset ID to delete images for
   * @returns Observable of true
   */
  deleteUserImagesInProfile(datasetId: string): Observable<true> {
    return this.http
      .get<IResponse>(`/employees/deleteuserimagesinprofile/${datasetId}`)
      .pipe(this.operator.extractResponse());
  }

  /**
   * Get employeeslist with all details like assigned groups and amount of views
   * @param queryObj - Object containing query params
   * @returns Observable of array of employees
   */
  get(queryObj?: IQueryObj): Observable<IEmployeeGet[]> {
    return this.http
      .get<IResponseData<IEmployeeGet[]>>(`/employees${this.queryHelperService.createQuery(queryObj)}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Get employeeslist with outdated sync data
   * @param filterOptions - The selected filter options
   * @param queryObj - Object containing query params
   * @returns Observable of employees array
   */
  getOutdatedEmployees(filterOptions: SyncProblemListFilterOptions, queryObj?: IQueryObj): Observable<OutdatedSyncGet> {
    const query = queryObj
      ? `${this.queryHelperService.createQuery(queryObj)}&filterData=${JSON.stringify(filterOptions)}`
      : `?filterData=${JSON.stringify(filterOptions)}`;

    return this.http.get<ResponseData<OutdatedSyncGet>>(`/v2/employees/outdated${query}`).pipe(
      map(value => {
        if (!value) {
          throw new Error(this.alert.translateDataNotLoaded());
        }
        return value.data;
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Gets Azure Sync user id
   * @param adminID -
   * @param syncAdminEmail -
   * @returns
   */
  getAzureSyncUsersID(adminID: string, syncAdminEmail: string): Observable<[] | IUser[]> {
    return this.http.get<[] | IUser[]>(`/employees/getAzureSyncUsersID/${adminID}/${syncAdminEmail}`).pipe(
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Get list of employees not part of one group
   * Includes details like assigned groups, campaign and signature
   * @param groupId - Group ID to check for
   * @param queryObj - Object containing query params
   * @returns Observable with list of employees not part of the group
   */
  getEmployeesNotMemberOfGroup(
    groupId: number,
    queryObj?: IQueryObj
  ): Observable<IEmployeeGetEmployeesNotMemberOfGroup> {
    return this.http
      .get<IResponseData<IEmployeeGetEmployeesNotMemberOfGroup>>(
        `/employees/notmemberof/${groupId}/${this.queryHelperService.createQuery(queryObj)}`
      )
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Get signature for employees
   * @param queryObj -
   * @returns List of employees that associated with this signature and it's respective dataset
   */
  getForSignature(queryObj?: IQueryObj): Observable<IUser[]> {
    return this.http
      .get<IResponseData<IUser[]>>(`/employees/employeesForSignature/${this.queryHelperService.createQuery(queryObj)}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the Google Sync user id.
   * @param adminId - The synced admin id
   * @param syncAdminEmail -  The synced admin email
   * @returns The user list
   */
  getGoogleSyncUsersID(adminId?: string, syncAdminEmail?: string): Observable<googleUser[]> {
    return this.http.get<IResponse | googleUser[]>(`/employees/getGoogleSyncUsersID/${adminId}/${syncAdminEmail}`).pipe(
      map(value => {
        // eslint-disable-next-line no-prototype-builtins
        if (value.hasOwnProperty('success')) {
          return [] as googleUser[];
        }
        return value as googleUser[];
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Check empid and activation code return all necessary data to show integration page
   * @param empId - The user id
   * @param activationCode - The activation code
   * @returns The integration data
   */
  getIntegrationData(empId: string, activationCode: string): Observable<ResponseGetIntegrationData> {
    return this.http
      .get<IResponseData<ResponseGetIntegrationData>>(`/account/employee/integrationdata/${empId}/${activationCode}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Get one employee without assigned groups and amount of views
   * @param id -
   * @returns
   */
  getOneSmallDetails(id: string): unknown {
    return this.http.get(`/employees/${id}/smalldetail`).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Get employeeslist without all details like assigned groups and amount of views
   * @returns
   */
  getSmallDetails(): unknown {
    return this.http.get('/employees/list/smalldetail').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Check if a given list of emails exist
   * @param emails - List of emails to check
   * @param datasetDefinition - Dataset definition to search for
   * @returns List of employees that exist and respective dataset
   */
  getUsersByEmail(emails: string[], datasetDefinition: string): Observable<IEmployeeGetUsersByEmail[]> {
    return this.http
      .post<IResponseData<IEmployeeGetUsersByEmail[]>>('/employees/emailsExist', {
        emails,
        definition: datasetDefinition
      })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Get employees list without unknown includes but impressions and clicks
   * @returns
   */
  getWithImpressionsAndClicks(): Observable<IEmployeeGetWithImpressionsAndClicks> {
    return this.http.get<IEmployeeGetWithImpressionsAndClicks>('/employees/list/impressionsandclicks').pipe(
      map(value => {
        if (!value.success) throw new Error(this.alert.translateDataNotLoaded());
        return value;
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Get employeeslist without all details like assigned groups and amount of views
   * @returns
   */
  getWithoutDefaultSignature(): unknown {
    return this.http.get('/employees/list/nodefaultsignature').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Assign one or many employee ids to group with given id
   * @param ids - Employee ids to move to new group
   * @param groupId - New group for employees
   * @param isAll - All selected  //TODO: check if not used in backend
   * @returns Observable of true
   */
  moveToGroup(ids: string[], groupId: number, isAll?: boolean): Observable<true> {
    return this.http
      .put<IResponse>('/employees/modify/group', {
        employees: ids,
        groupId,
        isAll
      })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Move one employee with given id to one or many groups with given ids
   * @param empId -
   * @param groupIds -
   * @returns
   */
  moveToManyGroups(empId: string, groupIds: number[]): Observable<IResponse | IResponseMessage> {
    return this.http
      .put<IResponse | IResponseMessage>('/employees/assign/groups', {
        employeeId: empId,
        groupIds
      })
      .pipe(this.operator.extractResponseOrMessage());
  }

  /**
   * Resends invitations to employees.
   * @param userIds - Array containing the employee ids to remind
   * @param forceResend - Used to bypass mailPolicy flag
   * @returns Observable of true
   */
  resendInvitation(userIds: string[], forceResend: boolean): Observable<ResponseMessageOrCode> {
    return this.http
      .post<ResponseMessageOrCode>('/employees/invitation/resend', {
        userIds,
        force: forceResend
      })
      .pipe(
        this.operator.extractResponseMessageStatusCode(),
        tap(response => {
          if (!response.success) {
            if (response.code) {
              this.alert.defaultErrorMessage(this.translate.translate('REMINDERS_NOT_SENT'));
            } else {
              this.alert.defaultErrorMessage(this.translate.translate(response.message as string));
            }
          } else {
            this.alert.defaultSuccessMessage(this.translate.translate('REMINDERS_SENT'));
          }
          if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.resend_invitation);
        })
      );
  }

  /**
   * Sends invitations to community edition employees.
   * @param userIds - The selected user ids
   * @returns The success
   */
  sendCommunityEditionInvitation(userIds: string[]): Observable<ResponseMessageOrCode> {
    return this.http.post<ResponseMessageOrCode>('/employees/invitation/resend', { userIds, freeUser: true }).pipe(
      this.operator.extractResponseMessageStatusCode(),
      tap(() => {
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.resend_invitation);
      })
    );
  }

  /**
   * Send email to users email adress with url where to edit own data
   * @param userId -
   * @returns
   */
  sendDataEditInfoMailToEmployee(userId: string): unknown {
    return this.http
      .post('/account/employee/senddataeditmail', { userId })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Sends feedback.
   * @param text -
   * @returns
   */
  sendFeedback(text: string): Observable<IResponseMessage> {
    return this.http
      .post<IResponseMessage>('/employees/feedback/webapp', { text })
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * Sends invitations to employees.
   * @param userIds - Ids of employees to notify
   * @param forceInvitation - Used to bypass mailpolicy
   * @param notificationStatus - Used for legal reasons
   * @returns Observable of true
   */
  sendInvitations(userIds: string[], forceInvitation: boolean, notificationStatus?: boolean): Observable<true> {
    return this.http
      .post<IResponse>('/employees/invitation/send', {
        userIds,
        forceInvitation,
        notificationStatus
      })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Sends an invitation test email.
   * @param email - account that should get an email
   * @returns Observable of true
   */
  sendInvitationTestmail(email: string): Observable<IResponseMessage> {
    return this.http
      .post<IResponseMessage>('/employees/invitation/sendinvitationtestmail', {
        email
      })
      .pipe(
        this.operator.extractResponseMessage(),
        tap(() => {
          if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.invitation_testmail_sent_to_admin);
        })
      );
  }

  /**
   * Sends other mail client notifications.
   * @param userId -
   * @param client -
   * @returns
   */
  sendOtherMailClientNotification(userId: string, client: unknown): unknown {
    return this.http
      .post('/employees/mailclients/other', { userId, client })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Sends the signature to the employee.
   * @param userId - The user id
   * @param signature - The signature string to send in email
   * @param type - The type of signature plain or html
   * @returns - The success true
   */
  sendSignaturToUser(userId: string, signature: string, type: SignaturePreviewType): Observable<true> {
    return this.http
      .post<Response>('/account/sendsignature', { userId, signature, type })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Set Data which is used for signature
   * @param datasetId -
   * @param tagObject -
   * @returns
   */
  setEmployeeInfoSingle(datasetId: string, tagObject: TSignaturePlaceholder): Observable<TSignaturePlaceholderValue> {
    return this.http
      .post<IResponseSetEmployeeInfoSingle>('/employees/userinfo/single', {
        datasetId,
        tagObject
      })
      .pipe(
        map(value => {
          if (!value.success) throw new Error(this.alert.translateDataNotLoaded());
          if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.user_info_data_changed);
          return value.savedObject;
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Set Data which is used for signature in enduser cockpit - without header token authetication
   * @param datasetId - THe dataset id
   * @param tagObject - The uploaded image object
   * @param empId - The employee id to authenticate the uer without token
   * @param activationCode - The activation code  to authenticate the uer without token(in case of enduser cockpit while user not logged in)
   * @param accountId - The account id
   * @returns The signature data
   */
  setEmployeeInfoSingleV2(
    datasetId: string,
    tagObject: TSignaturePlaceholder,
    empId: string,
    activationCode: string,
    accountId: string
  ): Observable<TSignaturePlaceholderValue> {
    return this.http
      .post<ResponseData<TSignaturePlaceholderValue>>(
        `/v2/account/employees/userinfo/single/${empId}/${activationCode}`,
        {
          datasetId,
          tagObject,
          accountId
        }
      )
      .pipe(
        map(value => {
          if (!value) {
            throw new Error(this.alert.translateDataNotLoaded());
          }
          if (environment.isCogSig) {
            this.intercomService.trackEvent(INTERCOM_DATA.user_info_data_changed);
          }
          return value.data;
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Used to set the signature graphic value in the database
   * @param signatureId - Signature id
   * @param tagObject - Graphic object
   * @param isRemoveBanner - Flag for remove uploaded banner
   * @returns - Observable of saved object
   */
  setSignatureGraphicSingle(
    signatureId: string,
    tagObject: TSignaturePlaceholder,
    isRemoveBanner: boolean
  ): Observable<TSignaturePlaceholderValue | undefined> {
    return this.http
      .post<IResponseSetEmployeeInfoSingle>('/employees/signaturegraphic/single', {
        signatureId,
        tagObject,
        isRemoveBanner
      })
      .pipe(
        map(result => {
          if (!result.success) throw new Error(this.alert.translateDataNotLoaded());
          if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.signature_modified);
          return result.savedObject;
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Uploads an image object and returns object containing link to image
   * @param tagObject - upload image object
   * @returns Object containing image information, including link to image
   */
  getUploadedImageData(tagObject: TSignaturePlaceholder): Observable<ISignaturePlaceholderValueImg> {
    return this.http
      .post<EmployeeGetUploadedImageData>('/employees/upload/graphic', {
        tagObject
      })
      .pipe(
        map(value => {
          if (!value.success) throw new Error(this.alert.translateDataNotLoaded());
          if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.user_info_data_changed);
          return value.savedObject;
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Uploads an image object and returns object containing link to image
   * @param tagObject - The uploaded image object
   * @param empId - The employee id to authenticate the uer without token
   * @param activationCode - The activation code  to authenticate the uer without token(in case of enduser cockpit while user not logged in)
   * @param accountId - The account id
   * @returns Object containing image information, including link to image
   */
  getUploadedImageDataV2(
    tagObject: TSignaturePlaceholder,
    empId: string,
    activationCode: string,
    accountId: string
  ): Observable<ISignaturePlaceholderValueImg> {
    return this.http
      .post<ResponseData<ISignaturePlaceholderValueImg>>(
        `/v2/account/employees/upload/graphic/${empId}/${activationCode}`,
        {
          tagObject,
          accountId
        }
      )
      .pipe(
        map(value => {
          if (!value) throw new Error(this.alert.translateDataNotLoaded());
          if (environment.isCogSig) {
            this.intercomService.trackEvent(INTERCOM_DATA.user_info_data_changed);
          }
          return value.data;
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Add users which will be synced with gmail apps wor work
   * @param userObject -
   * @returns
   */
  setUserManagedByAzure(userObject: IUser): unknown {
    return this.http
      .post('/employees/managedByAzure', { employeeObject: userObject })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Add users which will be synced with gmail apps for work
   * @param userObject - The user
   * @returns Observable of true
   */
  setUserManagedByGoogle(userObject: googleUser): Observable<true> {
    return this.http
      .post<IResponse>('/employees/managedByGoogle', { employeeObject: userObject })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Remove the assignment of one or many employees to one group
   * @param empIds - Array containing ids of employees to unassing
   * @param groupId - Group identifier
   * @returns Observable of true
   */
  unassignEmployeesFromGroup(empIds: string[], groupId: number[]): Observable<true> {
    return this.http
      .put<IResponse>('/employees/unassign', { employeeIds: empIds, groupId })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Remove the assignment from one employee to one or many groups
   * @param empId - Employee id to unassign
   * @param groupIds - Array of group ids to unassign from
   * @returns Observable of true
   */
  unassignEmployeeFromGroups(empId: string, groupIds: number[]): Observable<true> {
    return this.http
      .put<IResponse>('/employees/unassign/groups', {
        employeeId: empId,
        groupIds
      })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Updates the employee.
   * @param employee -
   * @returns
   */
  update(employee: IUser): Observable<boolean> {
    return this.http.put<IResponseMessage>('/employees', employee).pipe(this.operator.extractResponse());
  }

  /**
   * Updates Outlook Mac Sync for all the employees.
   * @param isAppleMailSync -
   * @returns
   */
  updateAllAppleMailSync(isAppleMailSync: boolean): Observable<IResponseMessage> {
    return this.http
      .put<IResponseMessage>('/employees/modify/syncAppleMail', {
        disableAppleMailSync: isAppleMailSync
      })
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * Updates Outlook Mac Sync for all the employees.
   * @param isOutlookMacSync -
   * @returns
   */
  updateAllOutlookMacSync(isOutlookMacSync: boolean): Observable<IResponseMessage> {
    return this.http
      .put<IResponseMessage>('/employees/modify/syncOutlookMac', {
        disableOutlookMacSync: isOutlookMacSync
      })
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * Updates the campaign dimension.
   * @param userIds -
   * @param state -
   * @returns
   */
  updateCampaignDimension(userIds: string[], state: string): unknown {
    return this.http
      .put('/employees/campaigndimensions', { state, userIds })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Updates the Easy Mac Sync property.
   * @param employees - Employees with updated properties
   * @returns Success of operation
   */
  updateEasyMacSync(employees: UpdateEasyMacSyncDTO[]): Observable<true> {
    return this.http
      .put<IResponse>('/employees/modify/easySyncMacAll', {
        employees
      })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Updates the custom attribute of the intercom company.
   * @param attr -
   * @param value -
   * @returns
   */
  updateIntercomCompanyCustomAttribute(attr: string, value: unknown): unknown {
    return this.http
      .put('/employees/intercom/CompanyCustomAttribute', {
        attribute: attr,
        value
      })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Update OWA signature status for given employees
   * @param employees -
   * @param newStatus -
   * @returns
   */
  updateOwaSignatureStatus(employees: string[], newStatus: boolean): unknown {
    return this.http
      .put('/employees/modify/owasignaturestatus', { employees, newStatus })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Updates the Sync Activation property.
   * @param ids - The selected user ids
   * @param isSyncActivated - The status of activation
   * @returns The response of update data
   */
  updateSyncActivation(ids: string[], isSyncActivated: boolean): Observable<IResponseMessageStatus> {
    return this.http
      .put<IResponseMessageStatus>('/employees/modify/syncActivate', {
        employees: ids,
        isSyncActivated
      })
      .pipe(this.operator.extractResponseMessageStatus());
  }

  /**
   * Updates the Sync user types.
   * @param ids -
   * @param isAutoSync -
   * @returns
   */
  updateSyncUsersType(ids: string[], isAutoSync: boolean): unknown {
    return this.http
      .put('/employees/modify/syncType', { employees: ids, isAutoSync })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Updates users sync property.
   * @param ids -
   * @returns
   */
  updateUserSync(ids: string[]): unknown {
    return this.http.post('/employees/updateuserSync', { empids: ids }).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Updates users data mapping configuration
   * @param mappedData - The array of mapping configuaration
   * @returns The status of success
   */
  updateImportUserDataMappingConfig(mappedData: EmpImportDataMappingFields[]): Observable<true> {
    return this.http
      .post<IResponse>('/accountInfo/importuser/datamapping', { importEmpDataSettings: JSON.stringify(mappedData) })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Gets users data mapping configuration
   * @returns The mapped data array
   */
  getImportUserDataMappingConfig(): Observable<EmpImportDataMappingFields[]> {
    return this.http.get<IResponseData<string>>('/accountInfo/importuser/datamapping').pipe(
      this.operator.extractResponseData(),
      map(data => JSON.parse(data) as EmpImportDataMappingFields[])
    );
  }

  /**
   * @deprecated replaced by `getEmployeeFullDetails`, `getEmployeeDetails` and `getEmployeeDefaultSignatures`
   *
   * Gets one employee.
   * @param id - Employee identifier
   * @returns Observable of employee information
   */
  getOne(id: string): Observable<IEmployeeGetOne> {
    return this.http.get<IResponseData<IEmployeeGetOne>>(`/employees/${id}`).pipe(this.operator.extractResponseData());
  }

  /**
   * Gets one employee with full details.
   * @param id - Employee identifier
   * @returns Observable of employee full details
   */
  getEmployeeFullDetails(id: string): Observable<EmployeeFullDetails> {
    return this.http
      .get<DataOrMessage<EmployeeFullDetails>>(`/v2/employees/${id}/fulldetails`)
      .pipe(this.operator.extractResponseDataOrMessage());
  }

  /**
   * Gets an employee details based on requirements
   * @param id - Employee identifier
   * @returns Observable of employee details based on requirements
   */
  getEmployeeDetails(id: string): Observable<EmployeeDetails> {
    return this.http
      .get<DataOrMessage<EmployeeDetails>>(`/v2/employees/${id}/details`)
      .pipe(this.operator.extractResponseDataOrMessage());
  }

  /**
   * Get employee deafault signatures
   * @param id - Employee identifier
   * @returns Observable of deafault employee details
   */
  getEmployeeDefaultSignatures(id: string): Observable<EmployeeDefaultSignatures> {
    return this.http
      .get<DataOrMessage<EmployeeDefaultSignatures>>(`/v2/employees/${id}/defaultsignatures`)
      .pipe(this.operator.extractResponseDataOrMessage());
  }

  /**
   * Gets an employee groups only.
   * @param id - Employee identifier
   * @returns Observable of employee groups
   */
  getEmployeeGroups(id: string): Observable<EmployeeGroupsBasicData> {
    return this.http
      .get<DataOrMessage<EmployeeGroupsBasicData>>(`/v2/employees/${id}/groups`)
      .pipe(this.operator.extractResponseDataOrMessage());
  }

  /**
   * Gets a list of employees
   * @param queryObj - Optional query object to filter employee list
   * @returns List of employees
   */
  getEmployeesForSignaturePreview(queryObj?: IQueryObj): Observable<EmployeeForSigPreview[]> {
    return this.http
      .get<ResponseData<EmployeeForSigPreview[]>>(
        `/v2/employees/htmlsigeditor${this.queryHelperService.createQuery(queryObj)}`
      )
      .pipe(this.operator.extractData());
  }
}
